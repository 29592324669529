import React, {Fragment} from "react"

import images from 'assets/images'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import $ from 'jquery';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/main.css'
import '../../assets/styles/navbar.css'
import '../../assets/styles/banner.css'
import '../../assets/styles/intro.css'
import '../../assets/styles/provider.css'
import '../../assets/styles/expand.css'
import '../../assets/styles/schedule.css'
import '../../assets/styles/testimonial.css'
import '../../assets/styles/footer.css'
import '../../assets/styles/buttons.css'
import '../../assets/styles/form.css'
import '../../assets/styles/learnCard.css'
import '../../assets/styles/preloader.css'
import './index.scss'


const App = () => {
  // function myFunction() {
  //   var x = document.getElementById("myTopnav");
  //   if (x.className === "toggle-nav") {
  //     x.className += "-show";
  //   } else {
  //     x.className = "toggle-nav";
  //   }
  // }
  return (
    <Fragment>
      <div className="preloader d-flex align-items-center justify-content-center">
        <div className="loader spinner-bg button-hover">
          <img src={images.logoLoader} alt=" "/>
        </div>
      </div>

      {}

      <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-fixed">
        <a href="#index"><img src={images.tortLogo} alt=" "/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          </ul>

          <div className="my-2 my-lg-0">
            <a className="link" href="#index">Home</a>
            <a className="link" href="#provider">Provider</a>
            <a className="btn cta-btn" href="#contact">Contact Us</a>
          </div>
        </div>
      </nav>

      <div id="index" className="banner">
        <div className="container d-flex flex-wrap align-item-center justify-content-center">
          <div className="left col-md-5 d-flex flex-column justify-content-center" >
            <h1>Find Top Doctors on Lien: Personal Injury Made Easy</h1>
            <p className="sub">Innovative one-stop platform to streamline your business today!</p>
            <a className="btn cta-btn" href="#learnMore">Learn More</a>
          </div>
          <div className="col-md-7 banner-image">
            <img src={images.bannerVector} alt=" "/>
          </div>

        </div>
      </div>

      <div id="learnMore"></div>

      <div className="intro-section container d-flex flex-wrap justify-content-center align-items-center">

        <div className="d-flex flex-wrap col-md-3">

          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="learn-card">
              <div className="icon">
                <img src={images.stethoscope} alt=" "/>
              </div>
              <h5 className="title">Medical Providers On Lien</h5>
              <p className="meta">Easily search for medical providers working on lien using the patient's location.</p>
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="learn-card">
              <div className="icon">
                <img src={images.calendar} alt=" "/>
              </div>
              <h5 className="title">Track Medical Appointments</h5>
              <p className="meta">Stay up-to-date with your patient's medical appointments, treatments and bills from each medical provider.</p>
            </div>
          </div>

        </div>

        <div className="-margin d-flex flex-wrap col-md-3">

          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="learn-card">
              <div className="icon">
                <img src={images.envelope} alt=" " />
              </div>
              <h5 className="title">Secure Messaging</h5>
              <p className="meta">HIPAA-compliant messaging and automatic notifications with patients and doctors to streamline your operations.</p>
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="learn-card">
              <div className="icon">
                <img src={images.files} alt= " "/>
              </div>
              <h5 className="title">Organized File Storage</h5>
              <p className="meta">Organize each patient's files and records in a secure HIPAA-compliant cloud-based storage for quick and easy access.</p>
            </div>
          </div>

        </div>




        <div className="descriptions col-md-6" data-aos="fade-left">
          <h1 className="heading">Why Use Tort?</h1>
          <p className="meta">With Tort, say goodbye to unnecessary calls and emails. We've built this platform to allow you to streamline your personal injury business – quickly find medical providers on lien, schedule and track medical appointments and treatments, communicate with all parties and store all data on one convenient platform.</p>
        </div>

      </div>

      <div className="sched-section">
        <div className="container d-flex align-items-center justify-content-center flex-wrap">

          <img className="col-md-5" data-aos="zoom-in" src={images.doctorImages} alt=" "/>

          <div className="description col-md-5" data-aos="zoom-out-left">
            <h5 className="section-title">Schedule a Product Demo</h5>
            <p className="meta">Our simple yet revolutionary HIPAA-compliant platform will connect you with our lawyers and patients. Utilize our virtual patient charts to communicate, send records and easily navigate this industry.</p>
            <a className="btn cta-btn" href="#contact">Contact Us</a>
          </div>

        </div>
      </div>

      <div id="provider" className="providers-section">
        <div className="container d-flex flex-wrap">
          <div className="col-md-5" data-aos="fade-up" data-aos-duration="3000">
            <h1 className="heading">Tort for Medical Providers</h1>
            <p className="sub">Doctors can join the Tort network to expand their practice in the personal injury industry!</p>
          </div>
          <div className="col-md-7"data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
            <p className="meta">Are you a medical professional in the personal injury space or are you interested in working on lien? Expand your practice today by joining the Tort network!</p>
            <p className="meta">Our simple yet revolutionary HIPAA-compliant platform will connect you with our lawyers and patients. Utilize our virtual patient charts to communicate, send records and easily navigate this industry.</p>
            <p className="meta">
              <a href="#contact">Contact Us</a>
              <span> to join Tort today!</span>
            </p>
          </div>
        </div>
      </div>


      <div className="expand-section">
        <div className="container d-flex justify-content-center align-items-center flex-column text-center">
          <h5 className="section-title" data-aos="fade-down">WAYS ON HOW TO EXPAND YOUR PRACTICE IN PERSONAL INJURY</h5>

          <div className="card-container">
            <div className="col-md-4" data-aos="zoom-in">
              <div className="expand-card">
                <div className="icon">
                  <img className="list-icon" src={images.lists} alt=" "/>
                </div>
                <h6 className="title">Get Listed On Our Network</h6>
              </div>
            </div>

            <div className="col-md-4" data-aos="zoom-in">
              <div className="expand-card">
                <div className="icon">
                  <img src={images.thumbsUp} alt=" "/>
                </div>
                <h6 className="title">Start Relationship with Law Firms</h6>
              </div>
            </div>

            <div className="col-md-4" data-aos="zoom-in">
              <div className="expand-card">
                <div className="icon">
                  <img className="person-icon" src={images.person} alt=" "/>
                </div>
                <h6 className="title">Accept Patients on Lien</h6>
              </div>
            </div>

            <div className="col-md-4" data-aos="zoom-in">
              <div className="expand-card">
                <div className="icon">
                  <img className="speaker-icon" src={images.speakerphone} alt=" "/>
                </div>
                <h6 className="title">Advertise Your Medical Practice</h6>
              </div>
            </div>

            <div className="col-md-4" data-aos="zoom-in">
              <div className="expand-card">
                <div className="icon">
                  <img className="line-icon" src={images.lineChart} alt=" "/>
                </div>
                <h6 className="title">Increase Business</h6>
              </div>
            </div>

          </div>
        </div>
      </div>



      <div className="testimonial-section container d-flex justify-content-center align-items-center flex-column text-center">

        <h5 className="section-title" data-aos="fade-down">TESTIMONIALS</h5>

        <div className="card-container">

          <div className="col-md-6" data-aos="zoom-in">
            <div className="testimonial-card">
              <div className="avatar">
                <img src={images.kinetics} alt=" "/>
              </div>
              <h6 className="name">Kinetics Theraphy Network</h6>
              <p className="meta">"We love using Tort. We are able to accept patients on lien and able to communicate with the law firm fast and easy."</p>
            </div>
          </div>


        </div>

      </div>

      <div className="contact-form d-flex align-items-center justify-content-center flex-column" id="contact">

        <h5 className="section-title text-center">CONTACT US</h5>

        <form className="col-md-5 col-sm-12">

          <p>All fields are rquired (*)</p>
          <div className="form-group">
            <input type="fullname" className="form-control input-btn" placeholder="First & lastname"/>
          </div>

          <div className="form-group">
            <input type="email" className="form-control input-btn" placeholder="Email address"/>
          </div>

          <div className="form-group">
            <input type="phone" className="form-control input-btn" placeholder="Phone number"/>
          </div>

          <div className="form-group">
            <select className="form-control input-btn">
              <option>Choose one ...</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <div className="form-group">
            <textarea className="form-control input-btn" placeholder="Message (10 Character minimum)" rows="4"></textarea>
          </div>

          <button type="submit" className="btn cta-btn">Send Message</button>
        </form>

      </div>

      <div className="footer">
        <div className="container d-flex flex-wrap">

          <div className="section col-md-5 d-flex align-items-start flex-column">
            <img className="logo" src={images.tortLogo} alt=" "/>
            <div className="links d-flex">
              <a href="https:/www.facebook.com/tortapp/" rel="noopener noreferrer" target="_blank">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
              <a href="https:/www.instagram.com/tort_app/" rel="noopener noreferrer" target="_blank">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>

            </div>
          </div>

          <div className="section col-md-4 text-left">
            <h5 className="title">CONTACT</h5>
            <p className="meta">Los Angeles, CA, USA</p>
            <p className="meta">Phone: (424) 231-5742</p>
            <p className="meta">Email:  hello@tortapp.com</p>
          </div>

          <div className="section col-md-3 d-flex flex-column text-left">
            <h5 className="title">CONTACT</h5>
            {/* <a className="meta link" href="#">LOG IN</a> */}
            <a className="meta link" href="#provider">PROVIDERS</a>
            <a className="meta link" href="#contact">CONTACT US</a>
          </div>

          <p className="meta">© 2020 Tort USA | All Rights Reserved</p>

        </div>
      </div>
    </Fragment>

    )
  }


export default App
