import React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
// import { persistCache } from "apollo-cache-persist";
import { withClientState } from "apollo-link-state";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import defaults from "./store/defaults";
import resolvers from "./store/resolvers";
import Routes from "./routes";
import "./index.css";

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers
});

const httpLink = createUploadLink({ uri: process.env.REACT_APP_API_URL });

const authLink = setContext((_, { headers }) => {
  const token = window.sessionStorage.getItem("AUTH_TOKEN");
  return {
    headers: {
      ...headers,
      authorization: token || ""
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([stateLink, authLink.concat(httpLink)]),
  cache,
  onError: e => console.log(e.graphQLErrors)
});

client.onResetStore(stateLink.writeDefaults);

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
};

export default App;
