import STORE_QUERIES from './queries'

const resolvers = {
  Mutation: {
    updateMyUser: (_, args, { cache }) => {
      const query = STORE_QUERIES.myUser
      const prev = cache.readQuery({ query })
      const data = {
        myUser: {
          ...prev.myUser,
          ...args,
        }
      }
      console.log({ prev, args, data })
      cache.writeQuery({ query, data })
      return data
    }
  }
}

export default resolvers
