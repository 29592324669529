const defaults = {
  myUser: {
    firstName: "",
    lastName: "",
    email: "",
    lastNameInitial: "",
    slug: "",
    type: "",
    __typename: "Lawyer"
  }
};

export default defaults;
