const images = {
  logo: require('./img/logo.png'),
  iphoneX: 'https://static.wixstatic.com/media/127e77_2a0a9841144442d4b2299c2a2aedad17~mv2_d_2000_2500_s_2.png/v1/crop/x_547,y_407,w_890,h_1657/fill/w_470,h_872,al_c,q_85,usm_0.66_1.00_0.01/TORTMockupbackground.webp',
  bannerVector: require('./img/banner-vector.png'),
  calendar: require('./img/calendar.svg'),
  doctorImages: require('./img/doctor-images.png'),
  files: require('./img/files.svg'),
  ionFacebook: require('./img/ion-facebook.svg'),
  envelope: require('./img/envelope.svg'),
  ionIg: require('./img/ion-ig.svg'),
  kinetics: require('./img/kinetics.png'),
  lineChart: require('./img/line-chart.svg'),
  lists: require('./img/lists.svg'),
  logoLoader: require('./img/logo-loader.svg'),
  scheduleVector: require('./img/schedule-vector.svg'),
  person: require('./img/person.svg'),
  speakerphone: require('./img/speakerphone.svg'),
  stethoscope: require('./img/stethoscope.svg'),
  thumbsUp: require('./img/thumbs-up.svg'),
  tortLogo: require('./img/tort-logo.svg')
}

export default images
