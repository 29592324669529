import gql from "graphql-tag";

const myUser = gql`
  query {
    myUser @client {
      email
      firstName
      lastName
      lastNameInitial
      avatar
      slug
    }
  }
`;

export default {
  myUser
};
